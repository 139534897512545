


const databits = [
  {
    title: '	Productos certificados',
    content:
      'Nuestros equipos cuentan con certificados de seguridad para así garantizar la seguridad y la salud de sus trabajadores.',
    
      featureName: 'seguridad ocupacional',
    
    featureImage: '../../images/IconeCertificados.svg'
  },
  {
    title: 'Seguridad ',
    content:
      'Nuestros equipos garantizan  total seguridad para sus colaboradores ante todos los riesgos que se encuentre expuesto en su lugar de trabajo.   ',
    
      featureName: 'Productos amigables con el medio ambiente.',
    
    featureImage: '../../images/IconeSeguridad.svg'
  }
  
  

];
export default databits;
